/*! Place your custom styles here */

.min-height-auto {
  min-height: auto;
}
.mt-26px{
  margin-top: 26px;
}
/* Starts : Buttons */

.btn--primary.v1 {
  background: none;
}

.btn--primary.v1 .btn__text {
  color: #ff1c1c;
}

.btn--primary.v1:hover,
.btn.v2:hover {
  background: #ff1c1c;
}

.btn--primary.v1:hover .btn__text,
.btn.v2:hover .btn__text {
  color: #fff;
}

.btn.v2:hover {
  background: #ff4f4f;
}

.btn.v2:hover {
  border-color: #ff4f4f !important;
}

.btn-apply {
background-color: #f8f8f8;
border-color: #f8f8f8 !important;
}

.btn-apply .btn__text {
color: #000 !important;
}
/* End : Buttons */
/* Starts : Jobs Left Middle right */

.maincolumns {
  color: #252525;
}

.maincolumns .section .view-more {
  padding: 24px 0;
  text-align: center;
}
/* End : Jobs Left Middle right */
/* .sidebar-channels sidebar custom CSS starts */

.sidebar-channels .row {
  margin-top: 16px;
  margin-bottom: 16px;
}

.sidebar-channels .row .channel-list-item {
  width: 100%;
}

.sidebar-channels .row .channel-list-item .channel-name {
position: relative;
z-index: 1;
padding-top: 6px;
padding-bottom : 6px;
}
.channels .section .boxed.sidebar-channels{
padding-bottom: 27px;
}

.sidebar-channels .row .channel-list-item .channel-name::after {
position: absolute;
bottom: 0;
right: -23%;
height: 100%;
width: 75%;
content: "";
background: linear-gradient(to left,
   rgba(255,255,255, 1) 20%,
   rgba(255,255,255, 0) 80%
);
pointer-events: none;
}

.sidebar-channels small {
  color: #888888;
  display: block;
  padding: 2px 0 4px 0;
}

.sidebar-channels,
.sidebar-channels .btn {
  line-height: normal;
}

.sidebar-channels .btn--sm {
  padding-left: 0.1em;
  padding-right: 0.1em;
}

.sidebar-channels .btn-block {
  text-align: center;
}

@media (max-width: 767px) {
  .sidebar-channels .row {
      padding-left: 15px;
      padding-right: 15px;
  }
}
/* sidebar-channels sidebar custom CSS end */
/* Starts : popularcompanies */

.popularcompanies {
  line-height: normal;
}

.popularcompanies .row {
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative;
  z-index: 0;
  padding-left: 55px;
  font-weight: normal;
}

.popularcompanies .row,
.popularcompanies span {
  display: block;
  color: #252525;
}

.popularcompanies .row:hover span span {
  text-decoration: underline;
}

.popularcompanies .row:hover span small {
  text-decoration: none;
}

.popularcompanies .row img.logo,
.popularcompanies .row div.logo {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 15px;
  top: 0;
  margin-bottom: 0;
}

.popularcompanies small {
  color: #888888;
  display: block;
  padding: 2px 0 4px 0;
}

.empty-sidebar {
left: -25px;
}

.empty-sidebar-search {
width: 200px;
}

@media (max-width: 767px) {
  .popularcompanies .row {
      padding-left: 65px;
      padding-right: 15px;
  }
}

.popularcompanies .row:before,
.popularcompanies .row:after{
  content: " ";
  display: table;
}

.popularcompanies .row:after{
  clear: both;
}

/* End : popularcompanies */
/* Starts : job-cards */

.job-cards {
  line-height: normal;
}

.job-cards a.row h4 {
  margin-bottom: 0;
}

.job-cards .row.post-text h4 {
  margin-bottom: 20px;
}

.job-cards .row {
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative;
  z-index: 0;
  padding-left: 85px;
}

.job-cards a.row,
.job-cards a.row span.col-sm-12 {
  display: block;
}

.job-cards .row:hover {
  border-color: #ff4f4f;
}

.job-cards .row:last-child {
  margin-bottom: 0;
}

.job-cards .row img.logo,
.job-cards .row div.logo {
  width: 52px;
  height: 52px;
  position: absolute;
  left: 20px;
  top: 26px;
  margin-bottom: 0;
}

.job-cards .row ul li {
  position: relative;
  z-index: o;
  padding-left: 1.35em;
  color: #252525;
  font-weight: normal;
}

.job-cards .row ul li .icon--sm {
  position: absolute;
  font-size: 1em;
  left: 0;
  top: 0.4em;
  color: #777777;
}

.job-cards .row.post {
  padding-left: 32px;
  padding-right: 32px;
}

.job-cards .row.post ul {
  list-style: inside;
}

.job-cards .row.post:hover {
  border-color: #ececec;
}

.job-cards .row.post .project-thumb.hover-element>a {
  height: 297px;
}

.job-cards .row.post .video-cover iframe {
  min-height: 308px;
}

@media (min-width: 1025px) {
  .job-cards {
      padding: 0 15px;
  }
}

@media (max-width: 1024px) {
  .job-cards .row img.logo {
      top: 22px;
  }
}

@media (max-width: 1023px) {
  .job-cards {
      margin-left: 15px;
      margin-right: 15px;
      margin-top: 16px;
  }
}

@media (max-width: 767px) {
  .job-cards {
      margin-left: 0;
      margin-right: 0;
  }
  .job-cards .row {
      padding-left: 85px;
      padding-right: 15px;
      margin-left: 0;
      margin-right: 0;
  }
  .job-cards .row.post {
      padding-left: 17px;
      padding-right: 17px;
  }
  .job-cards .row img.logo {
      top: 16px;
      left: 17px;
  }
  .job-cards .row ul.list-inline li {
      display: inline-block;
      margin-right: 20px;
  }
  .job-cards .row ul.list-inline li:last-child{
    border-right-width: 0;
  }
  .job-cards .row .icon--sm {
      top: 0.9em;
  }
  .job-cards a.row h4 {
      margin-bottom: 4px;
  }
}
/* End : jobcards */
/* End : Jobs Left Middle right */
/* Starts : imagebg */

.imagebg ul.list-inline li {
  position: relative;
  z-index: o;
  padding-left: 29px;
  color: #888888;
  font-weight: normal;
}

.imagebg ul.list-inline li .icon--sm {
  position: absolute;
  font-size: 1.5em;
  left: 0px;
  top: 2px;
  color: #888888;
}

@media (max-width: 736px){
  .imagebg .form-search button {
      margin-top: 0;
  }
  .imagebg .form-search button,
  .imagebg .form-search input {
      height:44px;
  }
  .btn:not(:last-child) {
      margin-bottom: 0;
  }
}

/* End : imagebg */
/* Starts : Rightcolumn, Jobs */

.rightcol .jobs {
  position: relative;
  z-index: 0;
  padding-top: 52px;
}

.rightcol .jobs .boxed {
  overflow: visible;
  padding-top: 70px;
  background: #f8f8f8;
}

.rightcol .jobs .logo {
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  z-index: 1;
}

.rightcol .jobs .logo img {
  width: 100px;
}

.rightcol .jobs .logo h2 {
  background: #ff4f4f;
  color: #fff;
  display: inline-block;
  height: 100px;
  width: 100px;
  line-height: 100px;
}

.rightcol .jobs .buttons .btn {
  margin: 0 0 16px 0;
}

@media (max-width: 1023px) {
  .rightcol .jobs {
      padding-top: 16px;
      padding-bottom: 16px;
  }
  .maincolumns.job .leftcol,
  .maincolumns.channel-company .leftcol,
  .maincolumns.channel-topic .leftcol {
      padding-top: 36px;
  }
}
/* End : Rightcolumn, Jobs */
/* starts : sticky-sidebars */

.sticky-sidebars {}

.sticky-sidebar-rightcol,
.sticky-sidebar-leftcol {
  will-change: min-height;
}

.sticky-sidebars .inner-wrapper-sticky {
  transform: translate(0, 0);
  /* For browsers don't support translate3d. */
  transform: translate3d(0, 0, 0);
  will-change: position, transform;
}

@media (max-width: 767px) {
.is-sticky .sticky{
  position: inherit !important;
}
}
/*
.sticky-sidebar-rightcol.is-affixed .sidebar__inner {
  margin-left: 15px;
}
*/
/* end : sticky-sidebars */
/* starts : talent-registration */

.talent-registration {}

.talent-registration .bg--white .input-icon i,
.talent-registration .bg--white .input-select::after {
  color: #b4b4b4;
}

.talent-registration .logo {
  width: 120px;
}
/* end : talent-registration */

.login-form .input-icon i {
  color: #b4b4b4;
}

.reset-password-form .input-icon i {
  color: #b4b4b4;
}


/* starts : company-registration */

.company-registration {}

.company-registration .bg--white .input-icon i,
.company-registration .bg--white .input-select::after {
  color: #b4b4b4;
}


/* end : company-registration */


/* starts : ML */

.lead {
  font-size: 1.15714286em;
  line-height: 1.48421053em;
}

.initials {
position: relative;
float: left;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
color: white;
font-weight: bold;
font-size: 20px;
}

/* end : ML */

.mainnav .menu-horizontal>li:not(:hover)>a,
.mainnav .menu-horizontal>li:not(:hover)>span,
.mainnav .menu-horizontal>li:not(:hover)>.modal-instance>.modal-trigger {
  opacity: 1;
}

/* Starts : Global modal classes wrappers */
.modal-dialog {
  min-width: 55%;
}
.modal-dialog .modal-content {
  padding: 32px;
}
.modal-header h3 {
  margin: 0;
}
.modal-header h3+p {
  font-size: 12px;
}
.modal-header .close {
  height: auto;
}
.modal-dialog .modal-footer {
  margin-top: 0;
}
.modal-dialog .modal-footer .btn:not(:last-child) {
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  .modal-dialog {
      min-width: 80%;
  }
}
/* End : Global modal classes wrappers */


/* starts : apply-job-overlay */
.slider-handle {
  background-color: #ff4f4f;
  background-image: none;
}

.disabled {
pointer-events: none;
cursor: default;
opacity: .5;
}

.validation-error-message {
display: block;
text-align: center;
margin: 10px auto;
font-size: 15px;
font-weight: 700;
color: red;
}

.apply-job-overlay .lead {
  text-align: right;
  font-weight: 600;
}

.apply-job-overlay form .row {
  padding: 20px 0;
  border: 1px solid rgba(236, 236, 236, 0.5);
  border-width: 1px 0 0 0;
}

.apply-job-overlay form .row.no-border {
  border-top-width: 0;
}

.apply-job-overlay form .row .row {
  padding: 0;
  border-width: 0;
}


.apply-job-overlay form>div[class*="col-"]:not(:last-child),
.apply-job-overlay form>.row>div[class*="col-"]:not(:last-child) {
  margin-bottom: 0;
}

.apply-job-overlay .tooltip {
  display: block;
  border-radius: 0;
  width: auto;
  height: auto;
  position: absolute;
  background: none;
}

.apply-job-overlay .modal-header p {
  color: #252525;
}

@media (max-width: 768px) {
  .apply-job-overlay .lead {
      text-align: left;
  }
  .apply-job-overlay .row {
      margin: 0;
  }
}


/* end : apply-job-overlay */

/* starts : Nav-container, alignmement issue in tablet resolutions */
@media (max-width: 1023px) {
  .nav-container  .bar__module{
      display: inline-block;
  }
  .nav-container .bar__module:not(:last-child) {
      margin-right: 0.928571em;
  }
  .nav-container .bar-1 .bar__module + .bar__module {
      margin-left: 1.85714em;
  }
  .nav-container .bar-2 .bar__module,
  .nav-container .bar-1 .bar__module{
      margin-bottom: 0;
  }
  .nav-container .bar-1 .menu-horizontal > li > a{
      position: relative;
      z-index: 0;
  }
  .nav-container .bar-1 .menu-horizontal{
      top:0;
  }
}
/* end : Nav-container, alignmement issue in tablet resolutions */
/* starts : Nav-container, alignmement issue in mobile resolutions */
@media (max-width: 767px) {
  .nav-container  .bar__module{
      display: block;
  }
  .nav-container .bar__module ul.menu-horizontal,
  .nav-container .bar__module ul.menu-vertical{
      display: block;
  }
  .nav-container .bar__module .dropdown .dropdown__content{
      padding: 0;
  }
  .nav-container .bar-1 .bar__module + .bar__module{
      margin-left: 0;
  }
}
/* end : Nav-container, alignmement issue in tablet resolutions */
/* starts : form-news-letteform-news-letterr */
.form-news-letter {}
.form-news-letter .input-checkbox+span {
  bottom: 8px;
}
@media all and (max-width: 1023px) and (min-width: 768px) {
  .form-news-letter button {
      margin-top: 0;
  }
}
/* end : form-news-letter */

/* starts : privacy-policy-page */
.privacy-policy-page{}
.privacy-policy-page .rightcol .jobs{
  padding-top:16px
}
.privacy-policy-page .rightcol .jobs .boxed{
  padding-top:31px;
  background: none;
}
.privacy-policy-page .no-bullets{
  line-height: normal;
}
.privacy-policy-page .no-bullets li{
  padding-bottom: 16px;
}
/* end : privacy-policy-page */
.job-cards a.row:hover {
  text-decoration: none;
}
.s-dashboard .myfeed .filter a.filter-btn.btn.btn--sm {
  text-decoration: none;
}


.skills span {
  background: #ececec;
  display: inline-block;
  padding: 6px 16px;
  margin: 0 4px 10px 0;
  border-radius: 50px;
}

/* starts : form-validation */

.validation-error {
  color: #ff0000 !important;
}
.validation-error-login {
  color: #ffffff !important;
}


/* end : form-validation */

/* starts : nav container */
.nav-container form:before,
.nav-container form:after{
  display: none;
}
.nav-container .bar__module.search input{
  height:24px;
}
/* end : nav container */

.rightcol .jobs .logo img,
.job-cards .row img.logo,
.job-cards .row div.logo,
.popularcompanies .row img.logo,
.popularcompanies .row div.logo,
.rightcol .jobs .logo h2{
  border: 1px solid #d4d4d4;
}
.job-cards .row img.logo,
.popularcompanies .row img.logo,
.popularcompanies .row div.logo,
.rightcol .jobs .logo h2,
.popularcompanies .row div.logo .initials,
.rightcol .jobs .logo h2 span{
  border-radius:50%;
}
.rightcol .jobs .logo h2{
  border-radius:15%;
}
.job-cards .row div.logo .initials,
.job-cards .row div.logo {
  border-radius: 8px;
}
.rightcol .jobs .logo img,
.job-cards .row img.logo,
.popularcompanies .row img.logo{
  padding: 3px;
  border-radius: 8px;
}
.rightcol .jobs .logo img{
  border-radius: 50%;
  height: 100px;
}
.job-cards .row div.logo .initials,
.popularcompanies .row div.logo .initials,
.rightcol .jobs .logo h2 span{
  border: 3px solid #fff;
}
.job-cards .row div.logo .initials,
.popularcompanies .row div.logo .initials{
  width: 100%;
  text-align: center;
}
.popularcompanies .row div.logo .initials{
  line-height: 32px;
}
.job-cards .row div.logo .initials{
  line-height: 44px;
}
.rightcol .jobs .logo h2 span{
  display: block;
  line-height: 92px;
}

/* starts : Pricing Table */
.comparison-table{
border-collapse:collapse;
}
.comparison-table .empty-cell{
border:none;
background:none;
}
.comparison-table td,
.comparison-table th{
padding:10px 15px;
border:1px solid #e9e9e9;
min-width:130px;
max-width:250px;
text-align:center;
}

.comparison-table-price td,
.comparison-table-price th{
padding:10px 15px;
border:1px solid #e9e9e9;
min-width:130px;
max-width:250px;
min-height: 100px;
text-align:center;
}
.comparison-table .comparison-table-section-header td{
font-weight:500;
font-size:12px;
padding-top:20px;
color:#222;
text-transform:uppercase;
background:#f9f9f9;
letter-spacing:1px;
}
.comparison-table .comparison-table-desc{
display:block;
font-size:11px;
font-weight: 600;
color:#4c4c4c;
max-height:0;
overflow:hidden;
margin:0;
}
.comparison-table tr:hover .comparison-table-desc{
max-height:250px;
color:#4c4c4c;
padding-top:8px;
transition:max-height .3s ease-in;
}
.comparison-table th{
background:#f9f9f9;
font-weight:500;
text-transform:uppercase;
color:#666;
padding-top:20px;
padding-bottom:20px;
letter-spacing:2px;
font-size:14px;
}
.comparison-table .comparison-table-pro{
background:#ff4f4f;
color:white;
border-color:#ff4f4f;
}
.comparison-table td:first-child{
text-align:left;
}
.comparison-table .fa-check{
color:#7ab55c;
}
.comparison-table .fa-close{
color:#b55c68;
}

@media (max-width: 735px){

.comparison-table td+td,
.comparison-table th+th {
  display:table-cell;
}
.comparison-table tr:hover .comparison-table-desc{
  max-height:none;
}
.comparison-table tr th:first-child {
  display: inherit;
  background: none;
  border:0;
}

section article{
  height: auto;
}

.comparison-table-footer td{
  max-width: none;
  min-width: none;
  display:block;float:left;width:100vw;
  text-align: center;
}

}

.fa-cog{
color:#b55c68;
}


/* end : Pricing Table */

/* Start : Pricing Table - Mobile */

/* DIRTY Responsive pricing table CSS */

/*
- make mobile switch sticky
*/

@media (max-width: 640px){

article {
width:100%;
max-width:1000px;
margin:0 auto;
height:1000px;
position:relative;
}
ul {
top:0px;
z-index:10;
padding-bottom:14px;
padding-left:15px;
}
li:last-child {
border-right:1px solid #DDD;
}
button {
width:100%;
border: 1px solid #DDD;
border-right:0;
border-top:0;
padding: 10px;
background:#FFF;
font-size:14px;
font-weight:bold;
height:60px;
color:#999
}
button.c-btn{
  height:50px;
}
li.active button {
background:#F5F5F5;
color:#000;
}
table { border-collapse:collapse; table-layout:fixed; width:100%; }
th { background:#F5F5F5; display:none; }
td, th {
height:53px
}
td,th { border:1px solid #DDD; padding:10px; empty-cells:show; }
td,th {
text-align:left;
}
td+td, th+th {
text-align:center;
display:none;
}
td.default {
display:table-cell;
}
.bg-purple {
border-top:3px solid #A32362;
}
.bg-blue {
border-top:3px solid #0097CF;
}
.sep {
background:#F5F5F5;
font-weight:bold;
}
.txt-l { font-size:28px; font-weight:bold; }
.txt-top { position:relative; top:-9px; left:-2px; }
.tick { font-size:18px; color:#2CA01C; }
.hide {
border:0;
background:none;

}


i.fa.fa-cog {
display:none;
  }
}


@media (min-width: 500px)

{
li.bg-blue.active{ display: none!important;}
li.bg-blue {display: none!important;}
}

.text-justify {
text-align: justify;
}

/* Geo Location Pricing Tables*/
.geoip { display: none !important; }
.geoip-country-GB .geoip-show-GB { display: block !important; }
.geoip-country-IE .geoip-show-IE { display: block !important; }
.geoip-country-US .geoip-show-US { display: block !important; }
.geoip-country-CA .geoip-show-CA { display: block !important; }

.geoip-hide { display: block !important; }
.geoip-country-IE .geoip-hide-IE { display: none !important; }
.geoip-country-GB .geoip-hide-GB { display: none !important; }
.geoip-country-US .geoip-hide-US { display: none !important; }
.geoip-country-CA .geoip-hide-CA { display: none !important; }

/* Nested List*/
.terms ol {
counter-reset: item}

.terms li:before {
content: counters(item, ".") " ";
counter-increment: item;
}

.btn-secondary:hover {
background-color: #d3d3d3;
border-color: #d3d3d3;
}
@media screen and (max-width: 1024px) and (min-width: 1000px) {
  .nav-container .bar__module.search input[type="search"] {
      width: 150px;
  }
}
@media (min-width: 1025px) {
  .nav-container .bar__module.search input[type="search"] {
      width: 416px;
  }
}
@media (max-width: 767px){
  footer ul,
  .post-text ul{
      display:block;
  }

  footer ul li:last-child,
  .imagebg ul.list-inline li:last-child,
  .post-text ul li:last-child{
      border-right: 0;
  }
  .app-details .tile{
      padding-bottom: 32px;
  }
  .imagebg ul.list-v1 li .icon--sm {
      position: relative;
      left: -12px;
  }
  /* Starts : Mobile menu styling */
  #menu1 .menu-horizontal > li,
  #menu1 .menu-vertical > li{
      padding: 6px;
      border-bottom: 1px solid rgba(0,0,0,0.1);
  }
  #menu1 .menu-vertical > li{
      padding: 4px;
  }
  #menu1 .menu-horizontal > li:last-child,
  #menu1 .menu-vertical > li:last-child{
      border-right:0;
      border-bottom: 0;
  }
  #menu1 .menu-horizontal > li > a,
  #menu1 .menu-horizontal > li > .dropdown__trigger{
      font-size: 1rem;
      font-weight: 600;
      color: #000;
      opacity: 1;
  }
  #menu1 .dropdown .dropdown__content:not([class='bg--dark']) .menu-vertical a{
      color: #000;
  }
  /* End : Mobile menu styling */
}

/* starts : companies logo */
.companies-logo{}
.companies-logo .logos {
padding: 0;
margin: 0;
list-style: none;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-orient: horizontal;
-webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
-ms-flex-pack: distribute;
    justify-content: space-around;
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
}
.companies-logo .logos .logo{
padding: 1rem;
text-align: center;
}
/* end : companies logo */

/* Starts : Login form password icon focus color change */
.password-icon-active i.material-icons{
color: #ff8282 !important;;
}
.cursor-hand{
cursor: pointer !important;
}
@media (max-width: 767px){
.input-icon i {
  top: 6px;
}
}
/* End : Login form password icon focus color change */

/* starts : cookie policy */

@keyframes slide-up {
  from {bottom: -100px;}
  to {bottom: 0;}
}

.cookie-policy-popup {
position: fixed;
background-color: rgba(0, 0, 0, 0.6);
text-align: center;
color: #fff;
display: block;
width: 100%;
left: 0;
bottom: 0;
z-index: 9999;
animation-name: slide-up;
animation-duration: 2s;
animation-timing-function: ease;
}

.cookie-policy-body {
position: relative;
display: block;
margin: 15px auto !important;
width: 90%;
}

.cookie-policy-text {
margin: 0;
}

.cookie-policy-close {
position: absolute;
height: 26px;
opacity: 1;
color: #fff;
right: 0;
top: 0;
font-size: 20px !important;
}

@media (max-width: 768px) {
.cookie-policy-body {
  width: 80%;
}

.cookie-policy-close {
  height: auto;
  width: auto;
  right: -15px;
  margin: 0;
}
}

/* ends : cookie policy */

/* start : landing banner */

.landing-banner-text {
text-align: initial;
}

.landing-banner-text p,
.notification .notification-close-cross {
color: #666666;
}

.landing-banner-text p a{
font-size: 1em;
}

/* end : landing banner */

/* start : gallery & content */

.swiper-container {
  padding-bottom: 10% !important;
}

@media (max-width: 567px) {
.swiper-container {
  padding-bottom: 56px;
}
}

.swiper-container .swiper-slide .project-thumb h4{
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}

.swiper-pagination-bullet {
  margin: 0 6px !important;
}

.slide-show-active-bullet {
  opacity: 1;
  background: #252525;
}

.lightbox .lb-close {
  background: none !important;
}

.project-thumb img {
   max-width: 100%;
   height: auto;
}

.project-thumb img {
   min-height: 270px;
   max-height: 270px;
}

@media (max-width: 567px) {
.swiper-container .swiper-slide .project-thumb h4{
   bottom: -18px;
   font-size: 14px;
   line-height: 18px;
}
}

/* end : gallery & content */
/* Starts : Global CSS classes */
.t-p-26{
padding-top: 26px;
}
/* End : Global CSS classes */

/* Starts : post-iframe-video */
iframe.post-iframe-video{
  height: 100%;
  min-height: auto;
}
/* End : post-iframe-video */

#company-content .imageblock .container ul{
  list-style: inherit;
}
.form--horizontal.search{}
.form--horizontal.search .equal{
  display: table;
  width: 100%;
}
.form--horizontal.search .equal .col{
  display: table-cell;
  padding: 0 15px;
}

@media (max-width: 990px){
  .form--horizontal.search button {
      margin-top: 0;
  }
}
@media (max-width: 767px){

  .form--horizontal.search .equal{
      display: block;
  }
  .form--horizontal.search .equal .col{
      display: block;
      padding: 0 0 12px 0;
  }
  .form--horizontal.search button {
     height: auto;
  }
}

.bar .logo {
  max-height: 36px;
  max-width: none;
}

.sticky-sidebars {}

.sticky-sidebar-rightcol,
.sticky-sidebar-leftcol {
  will-change: min-height;
}

.sticky-sidebars .inner-wrapper-sticky {
  transform: translate(0, 0);
  /* For browsers don't support translate3d. */
  transform: translate3d(0, 0, 0);
  will-change: position, transform;
}

@media (max-width: 767px) {
.is-sticky .sticky{
  position: inherit !important;
}
}

#info-tabs .tab__content {
white-space: pre-wrap;
}

section#search-query, #search-result {
padding-bottom: 0px !important;
}

/* resolution for mobile */
@media (max-width: 767px) {
.is-affixed .inner-wrapper-sticky{
  position: inherit !important;
}
.maincolumns.channel-topic div.rightcol{
  padding-top: 64px;
}
.info-tabs ul.tabs-content{
  display: block;
}
.info-tabs ul.tabs-content li:last-child{
  border: none;
}
}

/* sliding footer for job page */
#bottom-fixed-banner {
  display: none;
  padding: 10px;
}

#bottom-fixed-banner span {
  color: #fff;
}

.bottom-fixed {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 9999;
}

.btn-save {
border-color: #fff !important;
}

@media (max-width: 640px){
 .imageblock li:last-child {
     border-right:0;
 }
 footer.space--sm .container,
 footer.space--sm{
     height: auto !important;
 }
}

/* Starts : search section */
#search.boxed.boxed--lg{
  overflow:visible !important;
}
@media (min-width: 768px){
  #search.boxed.boxed--lg .equal .col:first-child,
  #search.boxed.boxed--lg .equal .col:last-child,
  #search-result .boxed.boxed--lg .equal .col:first-child,
  #search-result .boxed.boxed--lg .equal .col:last-child,
  #search-result .container .boxed.boxed--lg{
      padding-left:0;
      padding-right:0;
  }
  #search-result .container .boxed.boxed--lg{
      margin-left: -30px;
      margin-right: -16px;
  }
}
/* End : search section */

.job-cards .row.post ul {
list-style-position: outside;
}

.job-cards .row.post ul li {
padding-left: 0px;
margin-bottom: 4px;
}

.job-cards .row.post ul {
margin-left: 20px;
}

/* starts : tabs on serach page */
.search-results-tabs{
  border: 1px solid #ECECEC;
  border-radius: 6px;
  display: inline-block;
}

.search-results-tabs li:not(.active) {
  opacity: 1;
}

.search-results-tabs li {
  background: #fcfcfc;
  padding: 0;
  border-radius: 6px;
  border: none !important;
  display: inline-block;
}

.search-results-tabs li .tab__title div {
  padding: 7px 20px;
}

.search-results-tabs li .tab__title span {
  min-width: 90px;
  color: #252525;
}

.search-results-tabs li.active {
  background: #cacaca;
  border-color: #cacaca;
}

.search-results-tabs li.active .tab__title span {
  color: #ffffff;
}
/* end : tabs on serach page */

/* start : css on search filter */
.search-filter .boxed {
  background: #fafafa !important;
}

.search-filter .boxed .checkbox-wrapper {
  padding-top: 10px;
  padding-bottom: 3px;
  max-height: 350px;
  overflow: auto;
}

.search-filter .boxed input {
  background: #ffffff;
  border-color: #d3d3d3;
}

.search-filter .boxed input:focus {
  border-color: #ff8282;
}

.search-filter .input-checkbox label {
  float: left;
  background: #ffffff;
}

.search-filter .input-checkbox label span {
  margin-left: 32px;
}

.search-filter .input-checkbox input:checked + label:before {
  animation: none;
  -webkit-animation: none;
}

.search-filter .input-wrapper {
  padding: 5px 0 10px;
}

.search-filter .selected-job-types {
  padding-top: 15px;
}

.search-filter .selected-job-types li {
  background: #F2F2F2;
  display: inline-block;
  padding: 6px 15px 7px 10px;
  margin: 0 4px 10px 0;
  border-radius: 6px;
  font-size: 1em;
  line-height: 21px;
  color: #666666;
  font-weight: 600;
  border-color: #ECECEC;
}

.search-filter .selected-job-types li span{
  position: relative;
  z-index: 0;
  top: -1px;
  opacity: 0.5;
  cursor: pointer;
}

@media all and (min-width: 767px) {
  .search-jobs .job-list-item .boxed {
      display: flex;
      align-items: center;
  }
}

.search-bar .search-types {
  display: none;
}

@media all and (max-width: 767px) {
  .search-bar .search-types {
      display: block;
  }
}

/* end : css on search filter */

.feature:not(.boxed) {
margin-bottom: 60px;
}

.feature-1 > a.block + .feature__body {
border-radius: 0 0 6px 6px;
}

.boxed:not([class*='bg-']) {
background: #ffffff;
}

.boxed.boxed--border {
border: 1px solid #ececec;
}

.boxed {
position: relative;
overflow: hidden;
padding: 1.85714286em;
margin-bottom: 30px;
}

article.feature-1 h5 {
font-size: 18px;
}

.feature h5 {
margin: 0;
}

.feature-1 {
min-height: 11.14285714em;
}

.feature-1 p {
margin: 0;
}

article.feature-1 a {
font-size: 14px;
}

.feature-1 > a.block img {
border-radius: 6px 6px 0 0;
max-width: 100%;
min-width: 100%;
min-height: 197px;
max-height: 197px;
}

.feature-1 > a.block {
height: 70%;
}

.feature-1 > a.block .background-image-holder {
background-repeat: no-repeat;
background-position: center !important;
border-radius: 6px 6px 0 0;
position: relative;
opacity: 1;
}

@media all and (min-width: 991px) {
.feature-1 > a.block .background-image-holder {
  height: 197px;
}
}

.cards-min-height h5 {
min-height: 0 !important;
}

@media (max-width:991px) {
  .common-card .cards-min-height {
      min-height: 333px
  }
}
@media (min-width:992px) {
  .common-card {
      min-height: 400px
  }
}

.common-card div.block.background-image-holder img{
  display: block;
  opacity: 0;
}

/* New search page */

@media (min-width: 768px) {
.front-main .container {
  width: 95vw;
}
.job-list-item .row.boxed.boxed--border:hover {
  cursor: pointer;
}
.job-list-item .col-sm-3.text-center {
  display: none;
}
.job-list-item .col-sm-9 {
  width: 100%;
}
#filterForm .feature.feature-1.boxed.boxed--border {
  margin-bottom: 20px;
}
.search-job-detail {
  padding-top: 15px;
}
.search-job-detail .md-default-theme.md-mode-indeterminate {
  margin: 150px auto !important;
}
.search-job-detail .col-lg-6.middlecol {
  width: 100%;
}
.search-job-detail .col-lg-6.middlecol > h4 {
  display: none;
}
.search-job-detail #bottom-fixed-banner {
  display: none !important;
}
.search-job-detail .imagebg {
  padding: 0;
}
.search-job-detail .imagebg::before {
  background: transparent;
}
.search-job-detail .imagebg h1 {
  color: #000;
}
.search-job-detail .imagebg .buttons {
  margin: 20px 0;
}
.search-job-detail .imagebg [id^=jobBannerSaveJob],
.search-job-detail .imagebg [id^=jobBannerApplicationSubmitted],
.search-job-detail [id^=jobDetailApplicationSubmitted] {
  border-color: #000 !important;
}
.search-job-detail .imagebg [id^=jobBannerSaveJob] span,
.search-job-detail .imagebg [id^=jobBannerApplicationSubmitted] span,
.search-job-detail [id^=jobDetailApplicationSubmitted] span {
  color: #000 !important;
}
.search-job-detail .search-job-detail-container {
  border: 1px solid rgb(236, 236, 236);
  border-radius: 6px;
  max-height: 59.5vh;
  overflow: auto;
}
.search-job-detail .post-video,
.search-job-detail .post-image {
  display: none;
}
.search-job-detail .post-text {
  border-color: transparent;
  margin-bottom: 15px !important;
}
.search-job-detail .post-text:hover {
  border-color: transparent !important;
}
.search-job-detail .skills {
  border: none !important;
}
#filterForm .checkbox-wrapper span {
  width: 122px;
  display: block;
  margin-left: 30px;
}
.search-jobs .job-list-item .job-name {
  overflow-wrap: break-word;
  display: inline;
}
}

.search-jobs .job-list-item .job-name {
overflow: hidden;
width: fit-content;
}
.search-jobs .job-list-item .job-name h4{
margin-bottom: 0;
}

.search-job-detail .search-job-detail-logo {
display: flex;
justify-content: center;
}
.search-job-detail .search-job-detail-logo .logo-wrapper {
position: absolute;
margin-top: -26px;
background-color: #fff;
z-index: 999;
}
.search-job-detail .search-job-detail-logo .logo-wrapper a.logo{
  display: inline-block;
}
.search-job-detail .search-job-detail-logo .logo-wrapper img.logo,
.search-job-detail .search-job-detail-logo .logo-wrapper div.logo,
.search-job-detail .search-job-detail-logo .logo-wrapper a.logo {
width: 52px;
height: 52px;
border: 1px solid #d4d4d4;
border-radius: 15%;
}
.search-job-detail .search-job-detail-logo .logo-wrapper img.logo {
padding: 3px;
}
.search-job-detail .search-job-detail-logo .logo-wrapper div.logo .initials,
.search-job-detail .search-job-detail-logo .logo-wrapper a.logo .initials {
line-height: 44px;
border: 3px solid #fff;
width: 100%;
text-align: center;
border-radius: 15%;
}

@media (max-width: 768px) {
.maincolumns.front-main.m-pad-btm-0 {
  padding-bottom: 0;
}
}


.video-tiles .videobg {
  height: 240px;
  min-height: 240px;
  /* margin-bottom: 26px; */
  border-radius: 6px 6px 0 0;
}

.video-tiles .videobg iframe {
  top: -55px !important;
}

.video-tiles .videobg:hover .youtube-background {
  -webkit-filter: blur(4px);
  filter: blur(4px);
}

.video-tiles .videobg h1 {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.video-tiles .videobg .videobg-link {
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  text-indent: -99999px;
}

.menu-horizontal>li:not(:hover)>a,
.menu-horizontal>li:not(:hover)>span,
.menu-horizontal>li:not(:hover)>.modal-instance>.modal-trigger {
  opacity: 1;
}

.video-tiles.images .videobg .background-image-holder {
  opacity: 1 !important;
}

@media all and (max-width: 767px) {
  .search-jobs {
    display: table-footer-group;
  }
  .search-jobs > .section > h4 {
    margin-top: 1.36842105263158em;
  }
  .search-filter #filterForm{
    display: none;
  }
  .search-jobs .section,
  .search-filter .section {
    padding: 0 15px;
  }
  .search-results ul {
    padding: 0;
  }
  .job-list-item a div a {
    margin-top: 10px;
    width: 100%;
  }
  .search-jobs .job-cards .row {
    padding-left: 15px;
  }
  .search-jobs .job-cards .row div.col-md-9 {
    padding-left: 75px !important;
    top: 3px;
  }
}

#searchBarResults button {
  width: 100%;
}

@media (max-width: 990px){
  #show-banner div.col-md-4 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

/* starts, tiles-v1 */
.tiles-v1 {}

.tiles-v1 .boxed {
  overflow: visible;
  padding-top: 45px;
  text-align: center;
}

.tiles-v1 .s-logo {
  background-color: #cfcfcf;
  width: 62px;
  height: 62px;
  box-shadow: 1px 2px 3px rgba(5, 5, 6, 0.1);
  border-radius: 50%;
  overflow: hidden;
  margin-right: 24px;
  position: absolute;
  top: -31px;
  left: calc(50% - 31px);

}

.tiles-v1 .boxed ul {
  margin-bottom: 0;
  margin: 10px 0 64px 0;
}

.tiles-v1 .boxed ul li {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: rgb(151, 153, 157);
}

.tiles-v1 .boxed ul li .fa {
  width: 16px;
  text-align: center;
  margin-right: 6px;
}

.tiles-v1 .boxed ul li .fa.fa-map-marker {
  font-size: 17px;
}

.tiles-v1 .boxed .btn {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 0 0 6px 6px;
  padding: 0.92857143em;
  border-width: 0;
  background-color: #333;
}

.tiles-v1 .boxed .btn:hover {
  transform: none;
}

/* end, tiles-v1 */

#main-company .s-logo {
  background-color: #cfcfcf;
  width: 62px;
  height: 62px;
  box-shadow: 1px 2px 3px rgba(5, 5, 6, 0.1);
  border-radius: 50%;
  overflow: hidden;
  margin-right: 24px;
  margin-bottom: 20px;
}

.tiles-v1 .img img {
  transition: transform 0.5s ease;
}

.tiles-v1 .img, .tiles-v1 .img img {
  border-radius: 6px 6px 0 0;
}

.tiles-v1 .img {
  overflow: hidden;
}

.tiles-v1 .img a:hover img {
  transform: scale(1.1);
}

.youtube-background {
  position: absolute !important;
}

/* new block */
.tweets-feed div.user div[data-scribe*="component:author"] {
  display: none;
}

.job-cards .job-list-item a.row {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  position: relative;
  z-index: 0;
  padding-left: 100px;
  padding-right: 15px;
}

.job-cards .job-list-item {
  margin-top: 16px;
}

.job-cards .row img.logo,
.job-cards .row div.logo {
  width: 52px;
  height: 52px;
  position: absolute;
  left: 20px;
  top: 26px;
  margin-bottom: 0;
}

.job-cards .row .details {
  width: 100%;
  text-align: left;

}

.job-cards a.row h4 {
  margin-bottom: 0
}

.job-cards .row ul li {
  position: relative;
  z-index: 0;
  padding-left: 1.35em;
  color: #252525;
  font-weight: 400
}

.job-cards .row ul li .icon--sm {
  position: absolute;
  font-size: 1em;
  left: 0;
  top: .4em;
  color: #777
}

.job-cards .job-list-item a.row:hover {
  text-decoration: none;
  background: #ff4f4f;
}

.job-cards .job-list-item a.row:hover h4,
.job-cards .job-list-item a.row:hover ul li,
.job-cards .job-list-item a.row:hover ul li .icon--sm {
  color: #fff;
}

/* starts, fixing for version update issues */
.btn {
  white-space: nowrap;
}

.search-job-detail .col-lg-6.middlecol {
  max-width: 100%;
}

.search-results .search-results-tabs li {
  background: transparent;
}

.search-results .nav-tabs {
  margin-bottom: 26px;
}

.search-results .nav-tabs .nav-link {
  border: 0;
  background: #fcfcfc;
  border-radius: 6px;
  min-width: 130px;
  color: #252525;
  font-weight: 600;
  padding: 7px 20px 6px 20px;
  margin-bottom: 1px;
}

.search-results .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #cacaca;
  padding: 7px inherit;
  color: #fff;
  margin-bottom: 0;
}

.btn-secondary {
  background-color: transparent;
}

.badge,
.label {
  line-height: 1;
  white-space: nowrap
}

@media all and (max-width: 767px) {
  .search-results .search-jobs .section {
    width: auto;
  }
}

@media (min-width: 768px) {
  .md-max-width-none {
    max-width: none;
  }
}

.input-radio {
  margin-top: 0.46428571em;
}

#filterForm .input-radio label {
  float: left;
  background: #ffffff;
}

.input-radio label:not(:last-child) {
  margin-right: 0.46428571em;
}

/*.input-radio input:checked + label:after {*/
/*  left: 6px;*/
/*  top: 5px;*/
/*}*/

#search-jobkeyword, #search-joblocation {
  margin-bottom: 0.92857143em;
}

/* end, fixing for version update issues */

/* start : shimmer effect */
.shine {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 800px;
  display: inline-block;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

.line {
  height: 10px;
  width: 100%;
  display: block;
  margin-top: 10px;
}

.line:first-child {
  margin: 0;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
/* end : shimmer effect */

.yt-video-container {
  width: 640px;
  height: 385px;
}

.yt-video-background {
  width: 100vh;
  height: 100vh;
}

.yt-container {
  position:absolute;
  top: 0px;
  left:0;
  bottom: 0;
  right: 0;
  z-index: 0 !important;
}

.yt-wrapper {
  position: relative;
  padding-bottom:56.25%;
  height:0;
  overflow:hidden;
}

.yt-wrapper .yt-mask {
  position:absolute;
  left:0;
  top: 0;
  right: 0;
  bottom: 0;
}

.yt-wrapper iframe {
  position:absolute;
  top:-60px;
  height: calc(100% + 120px) !important;
  left:0;
  width:100%;
  height:100%;
}

.videobg .container, .videobg .background-image-holder {
  opacity: unset;
}

.feed a {
  text-align: left;
}


@media (max-width: 768px){
  .job-description {
    display: none;
  }
}

/* starts page-search-job */
@media (min-width: 992px) {
  .page-search-job .nav-container {
    min-height: 90px;
  }
}
.page-search-job section.comp-search-banner{
  padding-top: 60px;
  padding-bottom: 15px;
}
.page-search-job section.comp-search-banner:not(.pos-fixed) #search.boxed{
  padding: 0 !important;
  margin: 0;
}
.page-search-job .search-tabs .search-filters{
  padding: 0 15px 40px 15px;
}
/* end page-search-job */

@media (max-width: 767px) {
  section.job-banner .buttons .btn:first-child {
      margin-right:10px!important
  }
}

.view-more {
  padding-top: 15px;
  padding-bottom: 15px;
}

/* starts : No Articles */
.full-page{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.full-page .search-tabs {
  flex-grow: 1;
}
/* end : No Articles */

/* starts: Company jobs page */
.company-jobs-subheading {
  margin-bottom: 0;
  font-size: 2rem;
  padding-left: 30px;
}
@media (max-width: 767px) {
  .company-jobs-subheading {
    padding-left: 15px;
  }
}
/* ends: Company jobs page */

input[type]:focus, select:focus, textarea:focus {
  border-color: #252525;
}

.form-custom{
  background-color:#f4f0ec !important;
}
/*.form-custom::placeholder{color:#fff1e5}*/
/*.form-custom::-moz-placeholder{color:#fff1e5;opacity:1}*/
